/**
 * Sticky Header
 * Adds a class to header on scroll
 */

jQuery( document ).on( 'mouseover click', '.room-data-filter', function() {
	const myclass = jQuery( this ).attr( 'data-id' );
	jQuery( '.accomo-all' ).hide();
	jQuery( '.post-' + myclass ).show();
	jQuery( '.room-data-filter' ).removeClass( 'active' );
	jQuery( this ).addClass( 'active' );
} );
// var offsetheight = ;

/**
 * Document Ready Function
 * Triggered when document get's ready
 */

jQuery( document ).ready( function( jQuery ) {
	/**
	 *
	 * Script for label form
	 *
	 */

	jQuery( '.gfield input' ).each( function() {
		jQuery( this ).on( 'focus', function() {
			jQuery( this ).parent().parent( '.gfield' ).addClass( 'active' );
		} );

		jQuery( this ).on( 'blur', function() {
			if ( jQuery( this ).val().length == 0 ) {
				jQuery( this ).parent().parent( '.gfield' ).removeClass( 'active' );
			}
		} );

		if ( jQuery( this ).val() != '' ) {
			jQuery( this ).parent( '.css' ).addClass( 'active' );
		}
	} );

	jQuery( '.ginput_container_number,.ginput_container_email,.ginput_container_text,.ginput_container_post_tags,.ginput_container_post_title,.ginput_container_website,.ginput_container_phone' ).siblings( '.gfield_label' ).addClass( 'toggle_label' );

	jQuery( '.event-image-slider-one' ).boylenSlick( {
		slidesToShow: 3,
		slidesToScroll: 1,
		variableWidth: true,
		infinite: true,
		dots: false,
		fade: false,
		centerMode: false,
		arrows: true,
	} );

	jQuery( '.event-image-slider-two' ).boylenSlick( {
		slidesToShow: 3,
		slidesToScroll: 1,
		variableWidth: true,
		infinite: true,
		dots: false,
		fade: false,
		centerMode: false,
		arrows: true,
	} );

	jQuery( '.event-arrow-slider' ).boylenSlick( {
		slidesToShow: 3,
		slidesToScroll: 1,
		variableWidth: true,
		infinite: true,
		dots: false,
		fade: false,
		centerMode: false,
		arrows: true,
	} );

	/**
	 *
	 * Script for slick slider
	 *
	 */

	jQuery( '.rooms-images' ).boylenSlick( {
		slidesToShow: 1,
		slidesToScroll: 1,
		variableWidth: false,
		infinite: true,
		dots: false,
		arrows: true,
		fade: false,
		centerMode: false,
	} );

	// /**
	//  *
	//  * Script for Accomodation images slider
	//  *
	//  */

	jQuery( '.gallery-slider' ).boylenSlick( {
		slidesToShow: 3,
		slidesToScroll: 1,
		variableWidth: true,
		infinite: true,
		dots: false,
		arrows: true,
		fade: false,
		centerMode: false,
	} );

	/**
	 *
	 * Script for organic tabs
	 *
	 */
	jQuery( '.events-ctn' ).organicTabs( {
		speed: 200,
	} );

	jQuery( '.main-menu-ctn' ).organicTabs( {
		speed: 200,
	} );

	jQuery( document ).on( 'click', '.menu-title', function() {
		jQuery( this ).parent().find( '.menus' ).slideToggle();
	} );
	const selected_html = '';
	const selected = '';
	const parent_id = '';
	jQuery( document ).on( 'change', '.tag-search', function() {
		const parent = jQuery( this ).parents( '.menu-ctn' );
		const slug = jQuery( this ).val();
		jQuery( this ).find( 'option:first' ).text( 'Full Menu' );
		if ( slug == 'all' ) {
			jQuery( parent ).find( '.menu-item-all' ).show();
		} else {
			jQuery( parent ).find( '.menu-item-all' ).hide();
			jQuery( parent ).find( '.' + slug ).show();
		}
		if ( history.pushState ) {
			let oldurl = window.location.protocol + '//' + window.location.host + window.location.pathname;
			oldurl = oldurl + '?';
			oldurl += 'category=' + jQuery( this ).find( 'option:selected' ).attr( 'data-category' );
			oldurl += '&tag=' + jQuery( this ).val();
			window.history.pushState( { path: oldurl }, '', oldurl );
			location.reload();
		}
	} );

	jQuery( '.open-book-btns' ).on( 'click', function() {
		jQuery( '.book-now-buttons' ).toggleClass( 'showbook-buttons' );
		setTimeout( function() {
			jQuery( '.book-now-buttons' ).hide();
		}, 1000 );
	} );

	jQuery( '.book-my-table' ).magnificPopup( {
		type: 'iframe',
	} );

	jQuery( document ).mouseup( function( e ) {
		const container = jQuery( '.open-book-btns' );

		// if the target of the click isn't the container nor a descendant of the container
		if ( ! container.is( e.target ) && container.has( e.target ).length === 0 ) {
			jQuery( '.book-now-buttons' ).removeClass( 'showbook-buttons' );
		}
	} );
	jQuery( '.menu-item-inner' ).each( function( index, element ) {
		if ( jQuery( element ).children().length == 0 ) {
			jQuery( element ).addClass( 'no-post-found' );
		}
	} );
} );
